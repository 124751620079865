import { createIntl, createIntlCache } from 'react-intl';

import en from 'assets/en.png';
import es from 'assets/es.png';

import english from './languages/en';
import spanish from './languages/es';

export const messages = {
    en: english,
    es: spanish
};

export const getIntlContext = locale => {
    const cache = createIntlCache();
    return createIntl({
            locale,
            messages: messages[locale]
        },
        cache
    );
};

export const firebaseError = (error, locale) => {
    const intl = getIntlContext(locale);
    return intl.formatMessage({
        id: error,
        defaultMessage: messages[locale]['utils.default']
    });
};

export const availableLocales = Object.keys(messages);

export const browserLocale = navigator.language.split(/[-_]/)[0];

export const flags = {
    en,
    es
};
import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import firebase from 'firebase';
import { PersistGate } from 'redux-persist/integration/react';
import ReduxToastr from 'react-redux-toastr';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { createFirestoreInstance } from 'redux-firestore';
import { reduxFirebase as rfConfig } from 'config';
import Router from 'Router';

import LanguageWrapper from 'components/LanguageWrapper';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';

import './App.scss';



const App = (props) => {
    const { persistor, store, routes } = props;

    const rrfProps = {
        firebase,
        config: rfConfig,
        dispatch: store.dispatch,
        createFirestoreInstance
    };

    return ( <
        Provider store = { store } >
        <
        ReactReduxFirebaseProvider {...rrfProps } >
        <
        LanguageWrapper >
        <
        PersistGate persistor = { persistor } >
        <
        ReduxToastr newestOnTop = { false }
        preventDuplicates position = "bottom-right"
        getState = { state => state.toastr }
        transitionIn = "fadeIn"
        transitionOut = "fadeOut"
        progressBar closeOnToastrClick /
        >
        <
        Router routes = { routes }
        getState = { state => state.router }
        /> <
        /PersistGate> <
        /LanguageWrapper> <
        /ReactReduxFirebaseProvider> <
        /Provider>
    );
};

App.propTypes = {
    persistor: PropTypes.object.isRequired,
    routes: PropTypes.array.isRequired,
    store: PropTypes.object.isRequired
};

export default App;